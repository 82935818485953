import * as React from "react"

import Layout from "@components/layout"
import Seo from "@components/seo"
import WorkLayout from "@components/work/WorkLayout"

const IndexPage = () => (
  <Layout>
    <Seo
      title="Our Work"
      description="We are a group of dedicated workers made up of designers, strategists, writers, producers, editors, seers, thinkers, doers, go-getters."
    />
    <WorkLayout />
  </Layout>
)

export default IndexPage
